<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" role="columnheader">Code</th>
            <th class="text-center" role="columnheader">
              {{ $t("labels.display") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.qrcode" class="text-center">
            <td>{{ item.qrcode }}</td>
            <td>{{ item.text }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" sm="6">
        <v-btn color="info" @click="$refs.inputUploadFile.click()"
          ><v-icon>mdi-upload</v-icon> Upload</v-btn
        >
        <input
          type="file"
          ref="inputUploadFile"
          accept=".xlsx"
          @change="onInputFileChange"
          class="d-none"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex justify-end align-center">
          <v-text-field
            style="max-width: 120px"
            class="mr-2"
            dense
            outlined
            hide-details
            v-model.number="fontSize"
            type="number"
            label="Cỡ chữ (mm)"
            placeholder="Cỡ chữ (mm)"
          ></v-text-field>
          <v-autocomplete
            style="max-width: 150px"
            class="mr-2"
            dense
            outlined
            hide-details
            v-model="stampSize"
            :items="stampSizeOptions"
            label="Loại tem"
            placeholder="Loại tem"
          ></v-autocomplete>
          <v-btn
            color="success"
            @click="downloadStampFile"
            :disabled="!items || items.length === 0 || !stampSize"
            ><v-icon>mdi-check</v-icon> Lấy tem</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { STAMP_SIZE_OPTIONS } from "@/libs/const";
export default {
  name: "Index",
  data: () => ({
    items: [],
    stampSize: null,
    fontSize: 6,
    isLoading: false,
    stampSizeOptions: [...STAMP_SIZE_OPTIONS],
  }),
  methods: {
    async onInputFileChange(e) {
      const mappingFields = {
        Code: "qrcode",
        "Hiển thị": "text",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            item[mappingFields[i]] = v[i];
          });
          return item;
        });
        this.items = [...items];
      };
      await reader.readAsArrayBuffer(f);
    },

    async downloadStampFile() {
      this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-pdf`,
        {
          size: this.stampSize,
          font: this.fontSize,
          items: this.items,
        },
        `${this.stampSize}_x${this.items.length}_stamp.pdf`
      );
    },
  },
};
</script>

<style scoped></style>
